<template>
  <div class="schedule">
    <van-nav-bar
      title="时刻表"
      left-text="返回"
      right-text="首页"
      left-arrow
      @click-left="goBack"
      @click-right="goIndex"
    />
    <div class="card">
      <div class="direct van-ellipsis">方向：{{ to }}</div>
      <div class="details">
        <div class="first-line van-ellipsis">
          <div class="first float-left">
            <img src="~@/assets/images/icon_first.png" class="icon" />
            <span class="time">{{ beginTime }}</span>
            <img src="~@/assets/images/icon_last.png" class="icon" />
            <span class="time">{{ endTime }}</span>
          </div>
          <div class="second float-left van-ellipsis" @click="showAll">
            <span>{{ commonts }}</span>
          </div>
        </div>
        <div class="second-line" v-if="planTime">
          <span>起点预计发车：</span>
          <span>{{ planTime }}</span>
        </div>
      </div>
    </div>
    <div class="divide-line"></div>
    <div class="schedule-card">
      <div class="first-line"><span>首站发车时刻表</span></div>
      <div class="second-line"><span>以下时刻表根据历史计算得出，仅供参考</span></div>
      <div class="schedule-lists">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(route, index) in routeList" :key="index" :name="index">
            <template #title>
              <div>
                <div class="title-icon title-inline">
                  <span><van-icon name="clock-o" /></span>
                </div>
                <div class="title-time-div title-inline">
                  <span class="title-time">{{ route.group }}</span>
                </div>

                <div class="title-label-div title-inline" v-if="route.flag == 1">
                  <span class="title-label">早高峰</span>
                </div>
                <div class="title-label-div title-inline" v-if="route.flag == 2">
                  <span class="title-label">晚高峰</span>
                </div>
              </div>
            </template>
            <div class="route-list">
              <div class="route-item" v-for="(item, i) in route.times" :key="i">
                <span v-if="isNow(item)" style="color: #ff682c">{{ item.substr(1) }}</span>
                <span v-else>{{ item }}</span>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Dialog } from 'vant'
import { lineDetails, route } from '@/api/mygolbs'
export default {
  name: 'BusSchedule',
  props: ['lineName', 'routeId', 'to', 'upperOrDown'],
  data() {
    return {
      activeNames: [],
      routeList: [],
    }
  },
  computed: {
    ...mapGetters(['location', 'beginTime', 'endTime', 'planTime', 'commonts']),
  },
  activated() {
    this.init()
  },
  methods: {
    ...mapActions(['turnToSchedule']),
    goBack() {
      this.$router.back()
    },
    goIndex() {
      this.$router.push({ name: 'Home' })
    },
    showAll() {
      Dialog.alert({
        title: '提示信息',
        message: this.commonts,
        // theme: 'round-button',
      })
    },
    init() {
      if (!this.beginTime) {
        lineDetails(this.lineName, this.upperOrDown, this.location.city).then(res => {
          var params = {
            routeId: res.routeId,
            beginTime: res.beginTime,
            endTime: res.endTime,
            planTime: res.planTime,
            commonts: res.commonts,
          }
          this.turnToSchedule(params)
          this.queryBusSchedule()
        })
      } else {
        this.queryBusSchedule()
      }
    },
    queryBusSchedule() {
      console.log('获取时刻表')
      route(this.routeId, this.upperOrDown, this.location.city).then(res => {
        console.log('119', res)
        this.routeList = res.list
        var activeNames = []
        this.routeList.forEach((item, index) => {
          if (item.isNow == 1) {
            activeNames.push(index)
          }
        })
        this.activeNames = activeNames
      })
    },
    isNow(str) {
      return str.substr(0, 1) == '•'
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  margin: 20px;
  .direct {
    height: 20px;
  }
  .details {
    padding-top: 5px;
    .first-line {
      height: 20px;
    }
    .second-line {
      height: 15px;
      font-size: 14px;
    }
    .icon {
      height: 15px;
    }
    .time {
      padding-left: 2px;
      padding-right: 10px;
      font-size: 10px;
    }
    .float-left {
      float: left;
    }
    .float-right {
      float: right;
    }
    .first {
      height: 20px;
    }
    .second {
      width: calc(100vw - 160px);
      font-size: 14px;
    }
    .third {
      font-size: 14px;
      color: #2ab167;
      .schedule-list {
        padding-right: 20px;
      }
    }
  }
}
.divide-line {
  height: 10px;
  background-color: #f4f5f9;
}
.schedule-card {
  padding: 20px;
  .first-line {
    span {
      color: #000;
    }
  }
  .second-line {
    span {
      color: #ff682c;
      font-size: 14px;
    }
  }
  .schedule-lists {
    margin-top: 15px;
    .title-inline {
      display: inline-block;
    }
    .title-icon {
      padding: 0 10px;
    }
    .title-time-div {
      width: 80px;
    }
    .title-time {
      color: #898989;
      font-size: 10px;
    }
    .title-label-div {
      width: 44px;
      padding: 0 4px;
    }
    .title-label {
      font-size: 10px;
      color: #fff;
      background-color: #ff682c;
      border-radius: 8px;
    }
  }
}
.route-list {
  padding: 0 20px;
  .route-item {
    color: #333333;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 25%;
    height: 25px;
  }
}
</style>
